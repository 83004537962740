export const practiceData = [
  {
    id: 1,
    idval: 'litigation',
    title: 'LITIGATION',
    img: 'litigation-service.webp',
    description: 'Civil, Criminal, Labour Law, NCLT & MSME, Corporates and Testamentary'
  },
  {
    id: 2,
    idval: 'nonlitigation',
    title: 'NON – LITIGATION',
    img: 'nonlitigation-service.jpg',
    description: 'Agreements & Contract and Compliance & Documentation, '
  },
  {
    id: 3,
    idval: 'ipr',
    title: 'IPR – INTELLECTUAL PROPERTY RIGHTS',
    img: 'litigation-service.webp',
    description:
      'The Intellectual Property group is committed to the creation and protection of several portfolios in various industries, such as information technology, life sciences, communications and retail. The Firm provides advice to leading national and multinational companies regarding the acquisition, enforcement and exploitation of their intellectual property rights. This Includes Complete compliance of Copyright, Trademark, Patent, etc., National as well as International Registration and Compliance including Registration, Infringement, Assignee, Passing off, Opposition (civil as well as Criminal Remedy).'
  },
  {
    id: 4,
    idval: 'posh',
    title: 'POSH – PREVENTION OF SEXUAL HARASSMENT AT WORKPLACE',
    img: 'litigation-service.webp',
    description:
      'Includes complete compliance of the PoSH norms in the company as per the rules and guidelines provided by the Hon’ble Supreme Court of India, inclusive of spreading awareness via Presentation & Seminars in the Company, establishment of the PoSH committee, reporting to the directed authority as per the Court Guidelines, and providing Redressal.'
  },
  {
    id: 5,
    idval: 'redevelopment',
    img: 'litigation-service.webp',
    title: 'REDEVELOPMENT',
    description:
      'Handling entire management and documentational work during the entire term of redevelopment project, including but not limited to drafting and vetting of Development agreement, Permanent Alternate Accommodation, Share Transfer etc.'
  },
  {
    id: 6,
    idval: 'alternate',
    title: 'ALTERNATE DISPUTE RESOLUTION',
    img: 'litigation-service.webp',
    description:
      'Our firm provides advice and counselling on dispute resolution and have successfully assisted clients in a number of domestic and international arbitration besides commercial disputes. We specialize in providing Client focused legal services ranging from advisory services to Domestic & International arbitrations. This includes Arbitration, Conciliation, Mediation and Negotiation, from appointment of the arbitrator/ mediator to the final disposal of the case. '
  }
];

export const practicesubinfo = {
  litigation: [
    {
      id: 1,
      title: 'Civil',
      img: 'Civillaw.jpg',
      description:
        'Our primary aim is to help clients, both domestic and transnational, avoid litigation wherever possible. The Firm adopts robust and carefully crafted litigation strategy for clients, to ensure, to the extent possible, positions of dominance for settlement negotiations and full- blown litigations/dispute resolution proceedings, should the need arise. The Firm&apos;s Litigation practice is focused on Corporate Commercial Disputes including Shareholder Joint Venture Disputes, Contractual Disputes (Commercial contracts, Licensing contracts, Project contracts, Construction contracts, Real Estate contracts, Technology Transfer contracts, Asset and Business Transfer contracts and other Commercial contracts and arrangements), and Constitutional matters affecting clients&apos; business interests.',
      subparts:
        'We handle matters before the courts, quasi- judicial authorities, and tribunals including',
      points: [
        'Supreme Court of India',
        'High Courts of various States',
        'District Courts',
        'Consumer Protection Forum.',
        'Commercial Courts',
        'National Company Law Tribunal',
        'Banking Tribunals',
        'Competition Commission (and its predecessor the Monopolies & Restrictive Trade Practices Commission)'
      ]
    },
    {
      id: 2,
      title: 'Criminal',
      img: 'criminallaw.jpg',
      description: '',
      subparts: '',
      points: [
        'Criminal law is a specialized area of law that deals with cases where an individual or a group of individuals has been accused of committing a crime. At our law firm, we have a team of experienced criminal lawyers who specialize in a range of criminal matters, including but not limited to white-collar crimes, cheating, criminal breach of trust, defamation, sexual harassment (in relation to the Prevention of Sexual Harassment Act), Economic Offence Wing (EOW) matters, taxation matters, and cheque bounce cases (Negotiable Instrument).',
        'White-collar crimes refer to financial crimes committed by individuals or organizations in the course of their professional or business activities. Our team of criminal lawyers has extensive experience in handling white-collar crime cases and has successfully represented clients accused of financial crimes such as fraud, embezzlement, money laundering, and insider trading.',
        'Cheating is another common criminal offense that involves deceiving or misleading someone to gain an unfair advantage or to cause harm. Our lawyers have a deep understanding of the Indian Penal Code and can provide expert legal advice and representation in cheating cases.',
        'Criminal breach of trust refers to the act of dishonestly misappropriating or converting property entrusted to someone&apos;s care. Our criminal lawyers have extensive experience in handling criminal breach of trust cases and can provide expert legal representation to clients accused of this offense.',
        'Defamation is a criminal offense that involves making false and damaging statements about someone. Our lawyers have experience in handling defamation cases and can provide expert legal advice and representation to clients who have been accused of defaming someone or who have been defamed.',
        'Sexual harassment cases are governed by the Prevention of Sexual Harassment Act (PoSH). Our lawyers have experience in handling cases related to sexual harassment and can provide expert legal advice and representation to both individuals and organizations.',
        'EOW matters (Economic Offence Wing) refer to cases related to economic offenses, such as bank fraud, stock market manipulation, and money laundering. Our lawyers have extensive experience in handling EOW matters and can provide expert legal representation to clients accused of economic offenses.',
        'Taxation matters involve disputes related to tax laws and regulations. Our lawyers have a deep understanding of tax laws and can provide expert legal advice and representation in taxation matters.',
        'Cheque bounce cases (Negotiable Instrument) refer to cases where a cheque is returned by the bank due to insufficient funds. Our lawyers have experience in handling cheque bounce cases and can provide expert legal advice and representation to clients involved in such cases.',
        'At our law firm, we are committed to providing expert legal advice and representation to our clients in all criminal matters. Our team of experienced criminal lawyers has a deep understanding of criminal law and can provide clients with the best possible legal representation.'
      ]
    },
    {
      id: 3,
      title: 'Labour Law',
      img: 'labourlaw.jpg',
      description: '',
      subparts: '',
      points: [
        'Compliance and management of ESIC (Employee State Insurance Corporation) and PF (Provident Fund) are essential requirements for companies operating in India. Both schemes provide social security benefits to employees, including medical care, disability benefits, and retirement benefits. Companies need to comply with various regulations related to ESIC and PF, such as registration, contribution, and filing of various forms and returns.',
        'Effective management of ESIC and PF compliance requires regular monitoring of contributions, ensuring timely submission of returns, and proper maintenance of records. Non-compliance can lead to penalties and legal action.',
        'Industrial disputes, ESI Court matters, and labor disputes can arise due to various reasons such as non-payment of wages, discrimination, or violation of labor laws. Effective management of these disputes involves understanding the root cause of the dispute, communicating with the parties involved, and seeking legal advice and guidance to ensure compliance with relevant laws and regulations.',
        'Companies need to explore alternative dispute resolution methods such as mediation, negotiation, or arbitration to resolve the dispute. Engaging in litigation may also be necessary to protect the company&apos;s interests.',
        'In summary, compliance and management of ESIC and PF, industrial disputes, ESI Court matters, and labor disputes require a thorough understanding of the legal framework and regulations, as well as engaging legal experts to provide guidance and support. Companies need to ensure timely compliance and effective management to avoid penalties and legal action and maintain a healthy work environment.'
      ]
    },
    {
      id: 4,
      title: 'NCLT & MSME',
      img: 'nclt.webp',
      description:
        'National Company Law Tribunal (NCLT) is a quasi-judicial body in India that deals with corporate cases related to insolvency and bankruptcy, mismanagement of funds, revival, and declaration, merger and acquisition, conversion, and other related corporate cases. The tribunal also deals with matters related to Micro, Small, and Medium Enterprises (MSMEs).MSMEs are an important part of the Indian economy, and the government has taken several measures to support and promote their growth. Matters related to MSMEs that come before the NCLT include notice, filing of the matter, and handling the proceeding inclusive of arbitration/mediation.',
      subparts:
        'National Company Law Tribunal (NCLT) is a quasi-judicial body in India that deals with corporate cases related to insolvency and bankruptcy, mismanagement of funds, revival, and declaration, merger and acquisition, conversion, and other related corporate cases. The tribunal also deals with matters related to Micro, Small, and Medium Enterprises (MSMEs).',
      points: [
        'Insolvency and Bankruptcy: Insolvency and bankruptcy cases arise when a company is unable to pay its debts and is declared bankrupt. NCLT has the power to initiate insolvency proceedings against a company and appoint a resolution professional to manage the company&aposs affairs.',
        'Mismanagement of Funds: Mismanagement of funds cases arise when there are allegations of fraudulent or improper use of company funds by directors or officers. NCLT can take action against the concerned parties and order restitution of funds.',
        'Revival and Declaration: Revival and declaration cases involve the revival of companies that are in financial distress. NCLT can order the restructuring of the company or declare it as defunct.',
        'Merger and Acquisition: Merger and acquisition cases involve the amalgamation of two or more companies. NCLT has the power to approve or reject mergers and acquisitions.',
        'Conversion:Conversion cases involve the conversion of a company from one form to another, such as from a private limited company to a public limited company. NCLT can approve or reject such conversions.'
      ]
    },
    {
      id: 5,
      title: 'Corporates',
      img: 'corporatelaw.jpg',
      description:
        'Real Estate and Projects:- Our Firm provides sound, competent and comprehensive advice on all matters related to the real estate sector, in a cost- effective manner. The practice covers consulting on property development, sales, leases and acquisitions in Special Economic Zone, commercial, retail, industrial and residential sectors. The Real Estate group is proficient at offering strategic and transactional support while contending with challenges posed by the stringent real estate regulatory framework in India. Leading users of space, including technology service providers, financial institutions,investment banks, lenders, and major real estate developers rely on the Firm&apos;s expert advice for complex real estate transactions in emerging and established markets. Commercial and Corporate Advisory: We Advises on myriad commercial transactions such as licensing, franchising, joint venture, agency arrangements, leasing, service agreements, royalty and technical know-how, collaboration agreements etc. The Commercial Law and Corporate Advisory group represent an exemplary team of lawyers who provide tailored,practical advice to clients based on the legal and regulatory requirements of their business, we are regularly advising its clients in relation to general corporate, company law and Securities law issues that arise in the normal course of business. Business transactions in a wide range of key industry sectors, such as,finance, energy, infrastructure, manufacturing, technology, telecommunications and media, are effectively handled by the Firm&apos;s able lawyers.'
    },
    {
      id: 6,
      title: 'Family Law',
      img: 'familylaw.jpeg',
      description: '',
      subparts: '',
      points: [
        'Property-related disputes within the family, gift deeds, transfer of property, and divorce are some of the most common legal issues that people face in their personal lives. These issues can be complex and emotional, and they often require the help of legal professionals.',
        'Property-related disputes within the family can arise due to various reasons such as inheritance, partition of property, disputes related to joint ownership, or disputes related to tenancy. These disputes can be resolved through mediation or legal action in the court of law.',
        'Gift deeds and transfer of property are legal documents used to transfer the ownership of property from one person to another. These documents must be drafted carefully to ensure compliance with relevant laws and regulations.',
        'Divorce is a legal process that terminates a marriage. The process can be complex, especially if there are disputes related to property, child custody, and alimony. Legal professionals can provide guidance and support to individuals going through a divorce to ensure that their rights are protected and their interests are safeguarded.',
        'Effective management of property-related disputes within the family, gift deeds, transfer of property, and divorce requires a thorough understanding of the legal framework and regulations. Legal experts can provide guidance and support to individuals facing such issues. They can represent the individual in court proceedings, draft necessary legal documents, and negotiate settlements with the other party.',
        'In summary, property-related disputes within the family, gift deeds, transfer of property, and divorce are complex legal issues that require the expertise of legal professionals. Engaging a legal expert can help individuals navigate the legal process and ensure that their rights are protected. Effective management can help resolve disputes, transfer property legally, and safeguard the interests of all parties involved.'
      ]
    },
    {
      id: 7,
      title: 'Testamentary',
      img: 'practice.png',
      description: '',
      subparts: '',
      points: [
        'The drafting of a will, disbursement of property after the death of a member, and release deeds are all legal matters that involve the transfer of property. A will is a legal document that outlines how a person&apos;s property will be distributed after their death. The disbursement of property after the death of a member involves distributing property according to the will or, if there is no will, according to the laws of inheritance.',
        'Release deeds are legal documents that transfer ownership of property from one party to another. They are typically used when a person wants to transfer ownership of a property to someone else, such as a family member or a friend.',
        'Effective management of these matters requires a thorough understanding of the legal framework and regulations. Legal professionals can provide guidance and support to individuals and families facing such issues. They can draft necessary legal documents, provide guidance on inheritance laws and regulations, and negotiate settlements with other parties.',
        'In summary, matters related to drafting a will, disbursement of property after the death of a member, and release deeds require the expertise of legal professionals. Engaging a legal expert can help individuals navigate the legal process and ensure that their property is distributed according to their wishes or the laws of inheritance. Effective management can help resolve disputes, transfer property legally, and safeguard the interests of all parties involved.'
      ]
    }
  ],
  nonlitigation: [
    {
      id: 1,
      title: 'Agreements & Contract',
      img: 'practice.png',
      description:
        'Agreement related to Import & export; Property; Conveyance Agreement; Conducting Agreement; Shareholder Agreement; Partnership; Employment; Franchise; Endorsement; Business; Agreement regarding Construction and development, etc. This further includes drafting of the complete agreement/ master agreement and also contract vetting of National as well as International Documents.'
    },
    {
      id: 2,
      title: 'Compliance & Documentation',
      img: 'practice.png',
      description:
        'Inclusive of all kinds of documentational drafting and compliance as per the requirements Such as POSH, Labour law, Legal Metrology, RERA, MAHDA, etc.'
    }
  ],
  ipr: [
    {
      id: 1,
      title: 'INTELLECTUAL PROPERTY RIGHTS',
      img: 'practice.png',
      description:
        'The Intellectual Property group is committed to the creation and protection of several portfolios in various industries, such as information technology, life sciences, communications and retail. The Firm provides advice to leading national and multinational companies regarding the acquisition, enforcement and exploitation of their intellectual property rights. This Includes Complete compliance of Copyright, Trademark, Patent, etc., National as well as International Registration and Compliance including Registration, Infringement, Assignee, Passing off, Opposition (civil as well as Criminal Remedy).'
    }
  ],
  posh: [
    {
      id: 1,
      title: 'PREVENTION OF SEXUAL HARASSMENT AT WORKPLACE',
      img: 'practice.png',
      description:
        'Includes complete compliance of the PoSH norms in the company as per the rules and guidelines provided by the Hon’ble Supreme Court of India, inclusive of spreading awareness via Presentation & Seminars in the Company, establishment of the PoSH committee, reporting to the directed authority as per the Court Guidelines, and providing Redressal. '
    }
  ],
  redevelopment: [
    {
      id: 1,
      title: 'REDEVELOPMENT',
      img: 'practice.png',
      description:
        'Handling entire management and documentational work during the entire term of redevelopment project, including but not limited to drafting and vetting of Development agreement, Permanent Alternate Accommodation, Share Transfer etc.'
    }
  ],
  alternate: [
    {
      id: 1,
      title: 'ARBITRATION & CONCILIATION, MEDIATION',
      img: 'practice.png',
      description:
        'Our firm provides advice and counselling on dispute resolution and have successfully assisted clients in a number of domestic and international arbitration besides commercial disputes. We specialize in providing Client focused legal services ranging from advisory services to Domestic & International arbitrations. This includes Arbitration, Conciliation, Mediation and Negotiation, from appointment of the arbitrator/ mediator to the final disposal of the case. '
    }
  ]
};
