import React from 'react';
import { useNavigate } from 'react-router-dom';
import { practiceData } from '../../../data/data';
import { decode } from 'html-entities';

export default function PracticeArea() {
  let navigate = useNavigate();
  return (
    <div className="w-full p-4 md:p-16 pt-10">
      <div className="grid md:grid-cols-4 md:gap-4">
        {practiceData.map((value) => {
          return (
            <div
              key={value.id}
              className="flex flex-col m-4 items-center rounded-md bg-darkbgDefault drop-shadow-2xl shadow-3xl shadow-black-700/90 cursor-pointer hover:m-2"
              onClick={() => {
                navigate('/service/' + value.idval, { title: value.title });
              }}>
              <img
                src={require('../../../assets/images/' + value.img)}
                className="h-25 rounded-t-md"
              />
              <div className="p-3 pt-4 ml-4 mb-4 md:mb-[unset]">
                <p className="text-dark font-RaleBold capitalize text-sm md:h-10">
                  {decode(value.title)}
                </p>
                <p className="leading-5 h-20 text-md overflow-hidden font-RaleRegular mt-2">
                  {decode(value.description)}
                </p>

                {value.id == 1 ? (
                  <></>
                ) : value.id == 2 ? (
                  <></>
                ) : (
                  <p className="text-md font-RaleRegular">...</p>
                )}

                <button className="bg-darkbg text-light p-2 mt-2">Read More</button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
