import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { practicesubinfo } from '../../../data/data';
import Modal from 'react-modal';
import Footer from '../Footer';
import Header from '../Header';
import { decode } from 'html-entities';
import { Helmet } from 'react-helmet';

Modal.setAppElement('#root');

export default function PracticeDescription() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showval, setShowval] = useState(0);
  let params = useParams();
  // const title = params.title;
  const idval = params.idval;
  return (
    <>
      <Header />
      <Helmet>
        <title>Bhansali & Palekar - {idval}</title>
        <meta name="description" content="Legal Services" />
      </Helmet>
      <div className="bg-whiteDefault">
        <p className="capitalize bg-gradient-to-r from-dark to-gray-400 w-full p-16 text-center font-RaleRegular text-light text-3xl md:py-36 md:pb-20">
          {idval}
        </p>
        <div className="grid md:grid-cols-3 p-4 md:p-16 md:pt-10">
          {practicesubinfo[idval].map((value) => {
            return (
              <div
                key={value.id}
                className="flex flex-col m-auto p-5 bg-darkbg mb-5 items-center rounded-md drop-shadow-2xl shadow-3xl shadow-black-500/50 cursor-pointer"
                onClick={() => {
                  setShowval(value.id - 1);
                  setModalIsOpen(true);
                }}>
                <img src={require('../../../assets/images/' + value.img)} className="h-32 w-60" />
                <button className="bg-darkbgDefault mt-4 py-1 px-2 capitalize font-RaleSemiBold">
                  {value.title}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setModalIsOpen(false)}>
        <Helmet>
          <title>Bhansali & Palekar - {practicesubinfo[idval][showval].title}</title>
          <meta name="description" content={decode(practicesubinfo[idval][showval].description)} />
        </Helmet>
        <div className="bg-darkbg w-full md:h-full p-5 md:p-10 md:pt-5 ">
          <div className="relative cursor-pointer z-30" onClick={() => setModalIsOpen(false)}>
            <img
              src={require('../../../assets/images/cross.png')}
              className="h-4 md:h-6 right-[-20px] md:right-0 m-0 md:m-2 absolute"
            />
          </div>

          <p className="md:hidden text-default mt-5 text-2xl text-center capitalize font-RaleSemiBold">
            {practicesubinfo[idval][showval].title}
          </p>
          <div className="flex flex-col md:flex-row md:space-x-20 mt-5">
            <img
              src={require('../../../assets/images/' + practicesubinfo[idval][showval].img)}
              className="w-72 bg-darkbg rounded-lg"
            />
            <div>
              <p className="hidden md:block text-default text-4xl capitalize font-RaleSemiBold">
                {practicesubinfo[idval][showval].title}
              </p>
              <div className="md:overflow-scroll md:overflow-x-hidden md:h-[350px] text-justify">
                <p className="text-light font-RaleRegular pt-4  pr-2 md:pr-4">
                  {decode(practicesubinfo[idval][showval]?.description)}
                </p>
                {practicesubinfo[idval][showval]?.points && (
                  <>
                    <p className="text-light font-RaleRegular pt-4  pr-2 md:pr-4">
                      {decode(practicesubinfo[idval][showval].subparts)}
                    </p>
                    <ol className="text-light font-RaleRegular  pr-2 md:pr-4">
                      {practicesubinfo[idval][showval]?.points.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="text-light font-RaleRegular pt-4  pr-2 md:pr-4">
                            {index + 1 + '. ' + item}
                          </li>
                        );
                      })}
                    </ol>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
