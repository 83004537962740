import React from 'react';
import PracticeArea from './PracticeArea';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

export default function Practice() {
  return (
    <>
      <Helmet>
        <title>Bhansali & Palekar - Services</title>
        <meta name="description" content="Legal Services" />
      </Helmet>
      <Header />
      <div className="bg-whiteDefault">
        <div className="bg-no-repeat bg-cover w-[100%] h-[300px] bg-practiceslide">
          <p className=" w-full p-16 text-center font-RaleRegular text-light text-3xl md:py-36 md:pb-20">
            Services
          </p>
        </div>
        <PracticeArea />
      </div>
      <Footer />
    </>
  );
}
