import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function MainInfo(props) {
  return (
    <div className="pl-4 md:pl-16 h-full">
      <div className="absolute top-[20%] md:top-[35%] w-[75%] md:w-[50%]">
        {props.headText == 'Team' ? (
          <>
            <p className="text-4xl md:text-5xl font-RaleSemiBold text-black">{props.headText}</p>
            {/* <p className="text-lg md:text-2xl pt-3 font-RaleRegular text-black">
              {props.NormalText}
            </p> */}
          </>
        ) : (
          <>
            <p className="text-4xl md:text-5xl font-RaleSemiBold text-light">{props.headText}</p>
            <p className="text-lg md:text-2xl pt-3 font-RaleRegular text-light">
              {props.NormalText}
            </p>
          </>
        )}
        {props.headText != 'Legal solutions for you' && (
          <Link to={props.url} className="z-50 cursor-pointer">
            <p className="bg-gradient-to-r from-dark to-gray-400 w-[40%] md:w-[20%] cursor-pointer text-md md:text-xl mt-3 p-3 font-RaleRegular text-light">
              View More
            </p>
          </Link>
        )}
      </div>
    </div>
  );
}

MainInfo.propTypes = {
  headText: PropTypes.string,
  NormalText: PropTypes.string,
  url: PropTypes.any
};
