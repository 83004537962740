import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Practice from './Components/Practice/Practice';
import Home from './Components/Home/Home';
import PracticeDescription from './Components/Practice/PracticeDescription';
import Client from './Components/Clients/Client';
import Team from './Components/Team/Team';
import Terms from './Components/PolicyTerms/terms';
import PrivacyPolicy from './Components/PolicyTerms/PrivacyPolicy';
import ContactUs from './Components/PolicyTerms/ContactUs';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Bhansali & Palekar</title>
        <meta name="description" content="Trusted Legal Solutions" />
        <meta name="keywords" content="Legal Firm, Legal Solution, Court, Justice" />
      </Helmet>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/services" element={<Practice />} />
        <Route exact path="/clients" element={<Client />} />
        {/* <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/articles" element={<Articles />} />
        <Route exact path="/news" element={<News />} /> */}
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/service/:idval" element={<PracticeDescription />} />
        {/* <Route exact path="/bloginfo" element={<Bloginfo />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
