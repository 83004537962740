import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

export default function Client() {
  const [showCorporates, setShowCorporates] = useState(true);

  return (
    <>
      <Helmet>
        <title>Bhansali & Palekar - Clients</title>
        <meta name="description" content="Clients" />
      </Helmet>
      <Header />
      <div className="bg-whiteDefault">
        <p className="bg-no-repeat bg-cover h-[300px] bg-clientslide w-full p-16 text-center font-RaleRegular text-dark text-3xl md:py-36 md:pb-20">
          Clients
        </p>
        <div className="p-4 md:p-16 bg-darkbgDefault">
          <p className="font-RaleSemiBold text-dark text-xl">
            Our Well Known Clients provided with justice:
          </p>
          <div className="grid grid-cols-2 space-x-5 mt-5">
            <p
              className={
                `${showCorporates && 'border-[3px] '}` +
                'border-dark shadow-lg  text-center p-5 rounded-md font-RaleSemiBold text-dark text-xl cursor-pointer'
              }
              onClick={() => {
                setShowCorporates(true);
              }}>
              Corporates
            </p>
            <p
              className={
                `${!showCorporates && 'border-[3px] '}` +
                'border-dark shadow-lg  text-center p-5 rounded-md font-RaleSemiBold text-dark text-xl cursor-pointer'
              }
              onClick={() => {
                setShowCorporates(false);
              }}>
              Real Estate
            </p>
          </div>
          {showCorporates ? (
            <div className="mt-5 grid md:grid-cols-5 gap-4">
              <div className="p-4 font-RaleBold text-dark hover:text-black text-center text-sm border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img
                  src={require('../../../assets/images/GSCaltex.png')}
                  className="h-32 mb-4 m-auto"
                />
                {/* <p className="">GS CALTEX INDIA PVT LTD</p> */}
              </div>
              <div className="p-5 font-RaleBold text-dark hover:text-black text-center text-sm border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img src={require('../../../assets/images/iccl.png')} className="h-36 m-auto" />
                {/* <p className="">
                  INDIAN CLEARING CORPORATION LIMITED (ICCL) - A subsidiary of Bombay Stock Exchange
                  (BSE)
                </p> */}
              </div>
              <div className=" font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img src={require('../../../assets/images/RINA.png')} className="h-[95px] m-auto" />
                {/* RINA INDIA PRIVATE LIMITED – REGISTRO ITALIANO NAVALE (ITALITAN NAVAL REGISTER) */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-12 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img src={require('../../../assets/images/Remson.png')} className="h-20 m-auto" />
                {/* REMSONS INDUSTRIES PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img src={require('../../../assets/images/jcb.jpg')} className="h-20 mb-4 m-auto" />
                {/* JCB Brothers */}
              </div>
              <div className="p-5 font-RaleBold text-dark hover:text-black text-center text-sm border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img src={require('../../../assets/images/ascent.png')} className="h-36 m-auto" />
                {/* <p className="">ASCENT MEDITECH LIMITED</p> */}
              </div>
              <div className="p-6 font-RaleBold text-dark hover:text-black text-center text-sm border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img src={require('../../../assets/images/novo.png')} className="h-28 m-auto" />
                {/* <p className="">Novomedi sciences pvt Ltd</p> */}
              </div>
              <div className="p-10 font-RaleBold text-dark hover:text-black text-center text-sm border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img src={require('../../../assets/images/mahyco.png')} className="h-24 m-auto" />
                {/* <p className="">Mahyco pvt ltd</p> */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/Plada.png')}
                  className="h-36 mb-4 m-auto"
                />
                {/* PLADA INFOTECH PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/Dotsmark.png')}
                  className="h-36 mb-4 m-auto"
                />
                {/* DOTSMARK SYSTEMS INDIA LIMITED */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/Simson.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* SIMSON PHARMA LIMITED */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/emosons.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* EMSONS EXIM PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/Infexn.png')}
                  className="h-40 mb-4 m-auto"
                />
                {/* INFEXN PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/clentech.png')}
                  className="h-40 mb-4 m-auto"
                />
                {/* Cleantec Infra Pvt. Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-8 border-default hover:bg-white  shadow-lg border-2 rounded-md">
                <img
                  src={require('../../../assets/images/UCIL.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* UCIL SYNCHEM PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/engage.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* ENGAGE OUTDOORS MEDIA */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/grow.webp')}
                  className="h-20 mb-4 m-auto"
                />
                {/* Grow Indigo Pvt Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/ads1.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* Absolute Distribution Solution Pvt Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img src={require('../../../assets/images/ims.png')} className="h-20 mb-4 m-auto" />
                {/* Absolute Distribution Solution Pvt Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/pharmapoint.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* Absolute Distribution Solution Pvt Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/ShriRaj.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* SHREE RAJ */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/KHUSHI.png')}
                  className="h-36 mb-4 m-auto"
                />
                {/* KHUSHI MULTIPACK LLP */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/DELUX.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* DELUX BEARING PVT LTD */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-2 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/vaishali.png')}
                  className="h-36 mb-4 m-auto"
                />
                {/* Vaishali Pharma Ltd */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/bootique.png')}
                  className="h-20 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/eskay-resorts.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/binny-jewels.png')}
                  className="h-32 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/KOSCH.jpg')}
                  className="h-36 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/house-of-spice.jpg')}
                  className="h-32 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/pelltech-healthcaer.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/PRANJAL.jpg')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/kr-chauksey.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/BlueStarLogo.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/muza-hospitality.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/safetynet.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/shubham-tanks.jpg')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/TALWALKERS.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                <img
                  src={require('../../../assets/images/star-union.png')}
                  className="h-28 mb-4 m-auto"
                />
                {/* By The Boutique */}
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                INDUSTRIAL ENGINEERING SYNDICATE
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                OM PETRO CHEMICALS
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                DLXINFINITY DRIVELINE PVT LTD.
              </div>
              {/* <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
              <img
                src={require('../../../assets/images/Maruti.png')}
                className="h-20 mb-4 m-auto"
              />
              Maruti Air Couriers & Cargo Pvt Ltd
            </div> */}

              {/* <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
              PARVATI DEVELOPERS
            </div> */}
              <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
                LSD Lifestyle Pvt Ltd
              </div>

              {/* <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
              <img src={require('../../../assets/images/ads.jpg')} className="h-20 mb-4 m-auto" />
              Pharmapoint
            </div> */}

              {/* <div className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white  border-2 rounded-md shadow-lg">
              <img src={require('../../../assets/images/ads.jpg')} className="h-20 mb-4 m-auto" />
              IMS
            </div> */}
            </div>
          ) : (
            <div className="mt-5">
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 my-5 border-default hover:bg-white  border-2 rounded-md shadow-2xl">
                VINAYAK DEVELOPERS
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                PARVATI DEVELOPER
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                IGRAL GROUPS
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                RUDRIK DEVELOPER
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                PALLADIUM CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                PREMBINDU CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                MISQUITTA NAGAR CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                BHAVINI CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                PALLAVI CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                VASANT VILA CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                NEW AMISHA CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                PARADISE TOWER CHSL
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                Dahisar Sadguru Co. Op. Hsg. Ltd.
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                Juhu Prajakta Co. Op. Hsg. Ltd.
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                Shantidwar Co. Op. Hsg. Ltd.
              </div>
              <div className="font-RaleBold  text-dark hover:text-black text-center text-xl md:text-3xl p-5 border-default hover:bg-white  border-2 rounded-md my-5  shadow-2xl">
                Shreenath Bhavan Co. Op. Hsg. Ltd.
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
