import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { Helmet } from 'react-helmet';
// import GoogleMapReact from 'google-map-react';

export default function ContactUs() {
  //   const defaultProps = {
  //     center: {
  //       lat: 19.257462485059794,
  //       lng: 72.86111166005631
  //     },
  //     zoom: 11
  //   };
  return (
    <>
      <Helmet>
        <title>Bhansali & Palekar - Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <Header />
      <div className="bg-darkbgDefault h-screen">
        <p className="bg-gradient-to-r from-dark to-gray-400 w-full p-16 text-center font-RaleRegular text-light text-3xl md:py-36 md:pb-20">
          Contact Us
        </p>
        <div className="justify-center text-left w-[30%] m-auto bg-darkbgDefault align-middle mt-20">
          <p className="font-RaleSemiBold text-3xl text-dark">Mumbai</p>
          <p className="font-RaleSemiBold text-xl text-dark mt-5">Bhansali & Palekar</p>
          <p className="font-RaleBold text-sm text-dark mt-5">
            B-09, Suryakiran Apts, Avdhut Nagar, Dahisar East, Mumbai, Maharashtra 400068
          </p>
          {/* <p className="font-RaleBold text-sm text-dark mt-2">
            Phone: +919819116321, +919892059736
          </p> */}
          <p className="font-RaleBold text-sm text-dark mt-2">
            Email: kbhansaliconsultancy@gmail.com
          </p>
          <a
            href="http://maps.google.co.uk/maps?q=19.25678996777018, 72.85962620823786"
            className="font-RaleBold text-sm text-dark underline mt-2"
            target="_blank"
            rel="noreferrer">
            Get Direction
          </a>
        </div>
        {/* <div
          style={{ height: '400px', width: '400px' }}
          className="h-[400px] w-[400px] justify-center m-auto">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAIJsyBB26kIc7ulQFpC89p8huBC9zffiQ' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}></GoogleMapReact>
        </div> */}
      </div>
      <Footer />
    </>
  );
}
