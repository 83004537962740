import React from 'react';
// import ReactCardFlip from 'react-card-flip';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Navigation, Autoplay, Pagination } from 'swiper';

export default function FlipInfo() {
  // const [doFlip, setDoFlip] = useState(false);
  return (
    <div className="md:flex bg-darkbgDefault pt-10 pb-10 md:mb-[unset] justify-center">
      <div className="flex flex-col-reverse md:w-[70%] md:flex-row justify-between bg-no-repeat bg-cover md:p-20 text-left bg-introbg m-2 rounded-lg md:px-5">
        <div className="md:m-auto md:px-10">
          <div className="mt-10 md:mt-[unset] flex flex-col items-center">
            <img src={require('../../../assets/images/LOGO.png')} className="h-8" />
            <p className="font-RaleSemiBold text-xl text-light">Bhansali and Palekar</p>
            <p className="font-RaleBold text-sm text-white">Trusted Legal Solution</p>
          </div>
          <p className="text-left text-light text-lg px-4 md:px-[unset] md:text-lg font-RaleSemiBold mt-5">
            Established in the Suburbs of Mumbai, we are a full-service law – firm and one of the
            fastest growing legal firm in India. Our firm is built upon the ideology of excellence,
            and our dedication to achieving superior proficiency in all that we do. We take pride in
            delivering the best of our services to aid and facilitate our client at its best.
          </p>
          <p className="text-left text-light text-lg px-4 md:px-[unset] md:text-lg font-RaleSemiBold mt-5 mb-10">
            Leading in Litigation, IPR, Non – Litigation, Labour Law, Redevelopment, Commercial
            disputes, etc., we aim at providing a “One Stop Solution” for all the legal predicaments
            for our client and this has made us distinguishable and left our mark in the legal
            industry.
          </p>
        </div>
      </div>
      {/* <div
        onMouseEnter={() => setDoFlip(true)}
        onMouseLeave={() => setDoFlip(false)}
        className="m-auto mt-20 md:w-96">
        <ReactCardFlip isFlipped={doFlip} flipDirection="horizontal">
          <div className="bg-darkbg p-3 m-4 md:m-[unset] md:p-5 h-[500px]">
            <div className="p-5 flex justify-between items-center space-x-2">
              <img src={require('../../../assets/images/client.png')} className="h-[80px] " />
              <p className="leading-5 h-20 overflow-hidden text-white font-RaleRegular text-sm">
                The Marvel Civil War Dichotomy: Revisiting Morality Of Law
              </p>
            </div>

            <div className="p-5 flex justify-between items-center space-x-2">
              <p className="leading-5 h-20 overflow-hidden text-white font-RaleRegular text-sm">
                The Marvel Civil War Dichotomy: Revisiting Morality Of Law
              </p>
              <img src={require('../../../assets/images/client.png')} className="h-[80px] " />
            </div>
            <div className="p-5 flex justify-between items-center space-x-2">
              <img src={require('../../../assets/images/client.png')} className="h-[80px] " />
              <p className="leading-5 h-20 overflow-hidden text-white font-RaleRegular text-sm">
                The Marvel Civil War Dichotomy: Revisiting Morality Of Law
              </p>
            </div>
            <div className="p-5 flex justify-between items-center space-x-2">
              <p className="leading-5 h-20 overflow-hidden text-white font-RaleRegular text-sm">
                The Marvel Civil War Dichotomy: Revisiting Morality Of Law
              </p>
              <img src={require('../../../assets/images/client.png')} className="h-[80px] " />
            </div>
          </div>
          <div className="bg-darkbg p-3 m-4 md:m-[unset] md:p-5 h-[500px]"></div>
        </ReactCardFlip>
      </div> */}
    </div>
  );
}
