import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/Raleway-Black.ttf';
import './fonts/Raleway-Regular.ttf';
import './fonts/Raleway-SemiBold.ttf';
import './fonts/Raleway-Bold.ttf';
import './fonts/Raleway-ExtraBold.ttf';
import './fonts/Raleway-ExtraLight.ttf';
import './fonts/Raleway-Light.ttf';
import './fonts/Raleway-Medium.ttf';
import './fonts/Raleway-Thin.ttf';
import './fonts/Raleway-Italic.ttf';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import App from './pages/App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
