import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import FlipInfo from './FlipInfo';
import Slider from './Slider';

function Home() {
  return (
    <>
      <Header />
      <div>
        <Slider />
        <FlipInfo />
      </div>
      <Footer />
    </>
  );
}

export default Home;
