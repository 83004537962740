import React from 'react';
import MainInfo from '../MainInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Slider() {
  return (
    <>
      <Swiper
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        pagination={true}
        modules={[Navigation, Autoplay, Pagination]}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true
        }}
        className="homeslider">
        <SwiperSlide>
          <div className="bg-no-repeat mt-5 bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-mainslide">
            <MainInfo
              headText={'Legal solutions for you'}
              NormalText={
                'Whatever your rights you want we can bring it for you available at the easiest.'
              }
              url="/"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-no-repeat mt-5 bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-practiceslide">
            <MainInfo
              headText={'Legal Practice'}
              NormalText={'All the practices only to bring in the peace to all.'}
              url="/services"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-no-repeat mt-5 bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-clientslide">
            <MainInfo
              headText={'People relying on us for the trust'}
              NormalText={'Bringing the adjudicator to the people who trust us and believe us.'}
              url="/clients"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-no-repeat mt-5 bg-contain bg-center w-[100%] h-[380px] md:h-[480px] text-left bg-teamslide">
            <MainInfo
              headText={'Team'}
              NormalText={'Experts just to assist you for the best.'}
              url="/team"
            />
          </div>
        </SwiperSlide>
        {/* <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-blogslide">
        <MainInfo
          headText={'Blogs & Articles'}
          NormalText={'Educating people over the knowledge they deserve.'}
        />
      </div> */}
      </Swiper>
    </>
  );
}
