import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet';

export default function Team() {
  return (
    <>
      <Helmet>
        <title>Bhansali & Palekar - Team</title>
        <meta name="description" content="Legal Team" />
      </Helmet>
      <Header />
      <div className="pt-[40px]">
        <p className="bg-no-repeat bg-contain bg-center h-[400px] bg-teamslide w-full p-20 text-left font-RaleRegular text-dark text-3xl md:py-36 md:pb-20">
          Team
        </p>
        <div className="bg-darkbgDefault">
          <Swiper
            breakpoints={{
              // when window width is >= 640px
              100: {
                slidesPerView: 1
              },
              600: {
                slidesPerView: 3
              }
            }}
            slidesPerView={3}
            spaceBetween={30}
            slidesPerGroup={1}
            // loop={true}
            // loopFillGroupWithBlank={true}
            navigation={true}
            modules={[Navigation, Autoplay, Pagination]}
            autoplay={{
              delay: 6000,
              disableOnInteraction: true
            }}
            className="pt-5 team-slider">
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/ketan.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Ketan Bhansali
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Labour Law Consultant & Advisor
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/karan.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Karan Bhansali
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Advocate, High Court
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm text-left">
                    Partner
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/raveen.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Raveen Palekar
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Advocate, High Court
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm text-left">
                    Partner
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/prapti.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Prapti Bhadra
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Advocate, High Court
                  </p>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/yash.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Yash Thadeshwar
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Advocate, High Court
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/vipul.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Vipul Bhatt
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Advocate, High Court
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/harsh.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Harsh Mehta
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    Company Secretary
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/sanjay.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Sanjay Trivedi
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">Tax Consultant</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/sambhaji.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Sambhaji Gavare
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">
                    PF and ESIC expert
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/sachin.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Sachin Shetye
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">Team Member</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/vishal.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Vishal Rasalkar
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">Team Member</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/pradeep.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Pradeep Panchal
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">Team Member</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex flex-col m-auto  mb-5 items-center w-72 teamdiv">
                <img
                  src={require('../../../assets/images/samidha.jpg')}
                  className="h-[250px] mt-4 grayscale hover:filter-none"
                />
                <div className="px-6 text-center">
                  <p className="text-black capitalize font-RaleSemiBold text-xl mt-2">
                    Samidha Shivalkar
                  </p>
                  <p className="text-dark capitalize font-RaleSemiBold text-sm">Intern</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <Footer />
    </>
  );
}
