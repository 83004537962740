import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(true);
  const routeName = useLocation();
  const urlname = routeName.pathname.replace('/', '');
  const [showdisclaim, setShowDisclaim] = useState(false);

  const enterDisclaimer = () => {
    localStorage.setItem('disclaimer', false);
    setShowDisclaim(false);
  };

  useEffect(() => {
    if (localStorage.getItem('disclaimer')) {
      setShowDisclaim(false);
    } else {
      setShowDisclaim(true);
    }
  }, [showdisclaim]);

  useEffect(() => {
    const loaderElement = document.querySelector('.loader');
    if (loaderElement) {
      // loaderElement.remove();
    }
  }, []);
  return (
    <>
      <div className="md:flex md:justify-between px-4 md:px-16 md:w-full bg-darkbg py-1 md:fixed w-full z-10">
        <div className="flex flex-row justify-between items-center py-1">
          <Link to="/" className="flex items-center">
            <img src={require('../../assets/images/LOGO.png')} className="h-10 m-auto" />
            {/* <div>
          <p className="font-RaleBold text-2xl pt-2 pb-0 text-white">Bhansali & Palekar</p>
          <p className="font-RaleBold text-center text-[10px] text-white">
            TRUSTED LEGAL SOLUTIONS
          </p>
        </div> */}
          </Link>
          {navbarOpen ? (
            <img
              src={require('../../assets/images/menu.png')}
              className="h-8 md:hidden"
              onClick={() => setNavbarOpen(false)}
            />
          ) : (
            <img
              src={require('../../assets/images/full-menu.png')}
              className="h-8 md:hidden"
              onClick={() => setNavbarOpen(true)}
            />
          )}
        </div>
        <ul className="hidden md:flex justify-between items-center">
          <li className="">
            <Link to="/">
              <p
                className={
                  `${
                    urlname == ''
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Home
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/services">
              <p
                className={
                  `${
                    urlname == 'services'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Services
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/clients">
              <p
                className={
                  `${
                    urlname == 'clients'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Clients
              </p>
            </Link>
          </li>
          {/* <li className="">
        <Link to="/blogs">
          <p
            className={
              `${
                urlname == 'blogs'
                  ? 'font-RaleSemiBold text-[18px]'
                  : 'font-RaleRegular text-[15px] opacity-85'
              } ` + 'px-10 py-2 text-white'
            }>
            Blogs & Articles
          </p>
        </Link>
      </li> */}
          <li className="">
            <Link to="/team">
              <p
                className={
                  `${
                    urlname == 'team'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'px-10 py-2 text-white'
                }>
                Team
              </p>
            </Link>
          </li>
        </ul>

        <ul className={`${navbarOpen ? 'hidden' : 'block'}` + ' md:hidden'}>
          <li className="">
            <Link to="/">
              <p
                className={
                  `${
                    urlname == ''
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Home
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/services">
              <p
                className={
                  `${
                    urlname == 'services'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Services
              </p>
            </Link>
          </li>
          <li className="">
            <Link to="/clients">
              <p
                className={
                  `${
                    urlname == 'clients'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Clients
              </p>
            </Link>
          </li>
          {/* <li className="">
        <Link to="/blogs">
          <p
            className={
              `${
                urlname == 'blogs'
                  ? 'font-RaleSemiBold text-[18px]'
                  : 'font-RaleRegular text-[15px] opacity-85'
              } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
            }>
            Blogs & Articles
          </p>
        </Link>
      </li> */}
          <li className="">
            <Link to="/team">
              <p
                className={
                  `${
                    urlname == 'team'
                      ? 'font-RaleSemiBold text-[18px]'
                      : 'font-RaleRegular text-[15px] opacity-85'
                  } ` + 'md:px-10 pt-2 pb-1 md:py-2 text-white'
                }>
                Team
              </p>
            </Link>
          </li>
        </ul>
      </div>
      <Modal isOpen={showdisclaim}>
        <div className="bg-darkbgDefault w-full md:h-full md:p-10 pt-5">
          <p className="text-dark text-4xl capitalize text-center font-RaleSemiBold border-b-2 border-dark pb-2 md:pb-5 mx-8 md:mx-36">
            Disclaimer
          </p>
          <div className="flex flex-col items-center mt-2 md:mt-5">
            <p className="text-black text-justify font-RaleRegular z-50 text-sm mx-6 md:mx-16 mt-6 ">
              As per the rules of the Bar Council of India, we are not permitted to solicit work or
              advertise in any manner. By proceeding further and clicking on “I Agree” below, the
              user acknowledges that the transmission, receipt or use of the information on our
              website does not tantamount to solicitation, advertisement, inducement or personal
              communication of any sort for and on behalf of the Firm so as to create an
              attorney-client relationship.
              <br />
              <br />
              The information provided herein should not be interpreted as legal advice, for which
              the user must make independent inquiries. Whilst every effort has been taken to ensure
              the accuracy of the contents of this website, Bhansali & Palekar disclaims all
              liability arising from reliance placed by the user or any other third party on the
              information contained therein.
            </p>

            <button
              className="bg-dark m-auto text-center px-4 py-2 mt-8 md:mt-16 text-white"
              onClick={() => enterDisclaimer()}>
              I Accept
            </button>
            <a
              className=" m-auto text-center px-4 py-2 mt-2 mb-4 md:mb-[unset] text-dark underline"
              href="javascript:history.back()">
              Dismiss
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}
